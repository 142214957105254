import { atom, atomFamily, selectorFamily } from 'recoil';
import { eventDataState } from './event';

export const mapOverlaysState = atomFamily({
  key: 'mapOverlaysState',
  default: selectorFamily({
    key: 'mapOverlaysState/Default',
    get: id => ({ get }) => {
      const { map, overlays } = get(eventDataState(id));

      return [...[map], ...overlays].filter((val) => Object.keys(val).length !== 0);
    },
  }),
});

export const currentBaseLayerState = atom({
  key: 'currentBaseLayerState',
  default: 'Empty',
});

export const visibleMapOverlayState = atom({
  key: 'visibleMapOverlayState',
  default: [0],
});
