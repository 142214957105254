import { useRecoilState } from 'recoil';
import { trackSliceState } from './store/track';

function RouteSlider() {
  const [trackSlice, setTrackSlice] = useRecoilState(trackSliceState);

  const onSliderChange = (event) => {
    const percent = parseInt(event.target.value, 10);
    setTrackSlice(percent);
  };

  return (
    <div className="playbar grid grid-cols-playbar-compact grid-rows-2 md:grid-cols-playbar md:grid-rows-1 px-2 py-2 bg-gray-800 border-t border-gray-400 grid-flow-col-dense h-16 md:h-10">
      <div className="col-span-full row-start-1 md:col-span-5 md:row-start-auto">
        <input style={{ direction: "rtl" }} className="w-full h-full outline-none" type="range" onChange={onSliderChange} min="1" max="100" value={trackSlice} id="myRange" />
      </div>
    </div>
  )
}

export default RouteSlider;
