import { atomFamily, selectorFamily } from 'recoil';
import { fetchEventDataQuery } from '../api/client';
import { find } from 'lodash';

export const eventDataState = atomFamily({
  key: 'eventDataState',
  default: selectorFamily({
    key: 'eventDataState/Default',
    get: id => async ({ get }) => {
      return await get(fetchEventDataQuery(id));
    },
  }),
});

export const currentCompetitor = selectorFamily({
  key: 'currentCompetitor',
  get: ([eventId, competitorId]) => ({get}) => {
    const { competitors } = get(eventDataState(eventId));
    return find(competitors, { id: parseInt(competitorId) }) || competitors[0];
  }
});
