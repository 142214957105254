import { useRecoilValue } from 'recoil';
import { eventDataState } from './store/event';

import { useParams } from 'react-router-dom';
import Competitor from './Competitor';

function CompetitorList() {
  const { eventId } = useParams();
  const { competitors } = useRecoilValue(eventDataState(eventId));

  const sortedCompetitors = [...competitors].sort((a, b) => a.startnumber - b.startnumber);

  return sortedCompetitors.map((competitor) => (
    <Competitor key={competitor.id} competitor={competitor} selected={false} />
  ))
}

export default CompetitorList;
