import './MapView.css'
import { MapContainer, TileLayer, LayersControl } from 'react-leaflet'
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { eventDataState } from './store/event';
import { RotatedImageOverlay as ImageOverlay } from './vendor/RotatedImageOverlay';
import RouteEditor from './RouteEditor.jsx';

import {
  mapOverlaysState,
  visibleMapOverlayState,
  currentBaseLayerState,
} from './store/overlays';

function MapView() {
  const { eventId } = useParams();

  const { settings: { latitude: lat, longitude: lng, zoom } } = useRecoilValue(eventDataState(eventId));
  const maps = useRecoilValue(mapOverlaysState(eventId));
  const visibleMapOverlay = useRecoilValue(visibleMapOverlayState);
  const currentBaseLayer = useRecoilValue(currentBaseLayerState);

  const overlays = maps.map((map) => {
    const { coordinates, url, name } = map;
    const topLeft = [coordinates.topLeft.lat, coordinates.topLeft.lng];
    const bottomLeft = [coordinates.bottomLeft.lat, coordinates.bottomLeft.lng];
    const topRight = [coordinates.topRight.lat, coordinates.topRight.lng];

    return {
      name,
      url,
      topLeft,
      topRight,
      bottomLeft,
    };
  });

  return (
    <>
      {/* <RouteSlider /> */}
      {/* <h3>{competitor.startnumber} - {competitor.name}</h3> */}
      <div className="map flex-grow" style={{ position: 'relative' }}>
        <MapContainer
          style={{ height: '100%', width: '100%' }}
          center={[lat, lng]}
          zoom={zoom}
          drawControl={true}
          maxZoom={20}
          boxZoom={false}
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked={currentBaseLayer === 'OpenStreetMap'} name="OpenStreetMap">
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked={currentBaseLayer === 'Empty'} name="Empty">
              <TileLayer url="" />
            </LayersControl.BaseLayer>
            {
              overlays.map(({
                name,
                url,
                topLeft,
                topRight,
                bottomLeft,
              }, i) => (
                <LayersControl.Overlay
                  checked={visibleMapOverlay.includes(i)}
                  key={url}
                  name={name}
                >
                  <ImageOverlay
                    zIndex={i}
                    url={url}
                    topLeft={topLeft}
                    topRight={topRight}
                    bottomLeft={bottomLeft}
                  />
                </LayersControl.Overlay>
              ))
            }
          </LayersControl>
          <RouteEditor />
          {/* <MapDragHandler /> */}
        </MapContainer>
      </div >
    </>
  );
}

export default MapView;


