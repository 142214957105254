import React from 'react';
import { useParams, Link } from 'react-router-dom';

function Competitor({ competitor, selected }) {

  const { eventId, competitorId } = useParams();

  const {
    id,
    name,
    // marker_color: color,
    startnumber,
  } = competitor;

  // const hex2rgba = (hex, alpha = 1) => {
  //   const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  //   return `rgba(${r},${g},${b},${alpha})`;
  // };

  // const toggleCompetitor = () => {
  //   console.log('toggleCompetitor', id);
  // };

  // const colorStyle = {
  //   // "--tw-ring-offset-color": hex2rgba(color, 1),
  //   '--tw-ring-color': hex2rgba('#000000', 1),
  //   borderColor: hex2rgba(color, 1),
  //   backgroundColor: hex2rgba(color, 1),
  // };

  const label = () => (
    startnumber ? `${startnumber} - ${name}` : name
  );

  // <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  //  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M5 13l4 4L19 7" />
  // </svg>
  return (
    <li className={`flex flex-col px-2 pl-3${selected ? ' competitor__status--following' : ''}`}>
      <div className={`flex py-1 items-center rounded-md cursor-pointer${parseInt(competitorId) === id ? ' bg-gray-600' : ''}`}>
        {/* <div role="button" style={colorStyle} onClick={toggleCompetitor} onKeyPress={toggleCompetitor} className="flex-none h-4 w-4 border-2 rounded-full ring ring-offset-2 ring-offset-purple-100" /> */}
        <Link to={`/events/${eventId}/competitors/${id}`}>
          <div className="flex-1 pl-2 h-full text-white font-medium truncate ml-1 rounded-lg">{label()}</div>
        </Link>
      </div>
    </li>
  );
}

export default Competitor;
