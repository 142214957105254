import React, { Suspense } from 'react';
import './App.css';
import Home from './Home';
import EventView from './EventView';
import { RecoilRoot } from 'recoil';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <RecoilRoot>
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/events/:eventId" element={<EventView />} />
            <Route path="/events/:eventId/competitors/:competitorId" element={<EventView />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </RecoilRoot>
  );
}

export default App;
