import axios from 'axios';
import { selectorFamily } from 'recoil';

axios.defaults.withCredentials = true

export const fetchTrackData = async (eventId, competitorId) => {
  const response = await axios.get(`https://loggator.tv/api/events/${eventId}/competitors/${competitorId}/points`);

  return response.data;
};

export const fetchEventDataQuery = selectorFamily({
  key: 'fetchCompetitorsQuery',
  get: (id) => async () => {
    const response = await axios.get(`https://loggator.tv/api/events/${id}.json`);
    return response.data;
  },
});

export const updatePointLocation = async (eventId, pointId, data) => {
  const payload = {latitude: data.latitude, longitude: data.longitude };
  const response = await axios.put(`https://loggator.tv/api/events/${eventId}/points/${pointId}`, payload);

  return response.data;
};
