import React from 'react';

import CompetitorList from './CompetitorList';

function Sidebar() {
  return (
    <aside className="sidebar sm:relative absolute border-r border-gray-400 z-999 flex flex-col bg-gray-800 text-white w-64 top-0 bottom-0">
      <div className="flex absolute inset-0 flex-col">
        <div className="overflow-y-auto flex-1">
          <CompetitorList />
        </div>
        <div className="flex-none z-10">
          {/* <CompetitorSearch /> */}
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;

// absolute flex bg-gray-200 text-white w-56 top-0 bottom-0
