import React, { Suspense } from 'react';
import Sidebar from './Sidebar';
import MapView from './MapView';
import Header from './Header';
import RouteSlider from './RouteSlider';


function App() {
  return (
    <div className="flex flex-col fixed inset-0 text-white">
      <div className="flex-none">
        <Suspense fallback={<div>Loading</div>}>
          <Header />
        </Suspense>
      </div>
      <div className="flex-grow flex flex-row relative">
        <Suspense fallback={<div>Loading</div>}>
          <Sidebar />
          <main className="flex flex-col flex-grow map-pattern">
            <MapView />
            <div className="flex-none z-999">
              <RouteSlider className="flex-none" />
            </div>
          </main>
        </Suspense>
      </div>
    </div>
  );
}

export default App;
