import { useEffect, useState, useRef, useMemo } from 'react';
import { Polyline, Marker, useMapEvents } from 'react-leaflet'
import { fetchTrackData, updatePointLocation } from './api/client';
import { useParams } from 'react-router-dom';
import { findIndex } from 'lodash';
import { replaceItemAtIndex } from './util';
import { useRecoilValue } from 'recoil';
import { trackSliceState } from './store/track';
import RefreshControl from './RefreshControl';
import L from 'leaflet';
import 'leaflet-pather';

function RouteEditor() {
  const { eventId, competitorId } = useParams();
  const [track, setTrack] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const trackSlice = useRecoilValue(trackSliceState);

  const partialTrack = useMemo(() => {
    const cnt = Math.floor(track.length * trackSlice / 100 * -1);
    return track.slice(cnt);
  }, [trackSlice, track]);

  const polyline = partialTrack.map(p => L.latLng(p.latitude, p.longitude))
  const polylineOptions = { color: 'red', weight: 1, fillOpacity: 1 };

  useMapEvents({
    click(e) {
      if (!e.originalEvent.shiftKey) return;
      if (!selectedPoint) return;

      const { lat, lng } = e.latlng;
      const index = findIndex(track, { id: selectedPoint });
      let newPoint = track[index + 1];
      if (newPoint)
        movePoint(newPoint.id, lat, lng);
    }
  })

  const markerIcon = useRef(
    new L.DivIcon({
      iconSize: new L.Point(10, 10),
      className: 'bg-sky-600 rounded-full border-2 border-solid border-gray-100 leaflet-editing-icon'
    })
  )

  const selectedMarkerIcon = useRef(
    new L.DivIcon({
      iconSize: new L.Point(14, 14),
      className: 'bg-red-500 rounded-full border-2 border-solid border-gray-100 leaflet-editing-icon'
    })
  )

  const updatePointState = (index, point) => {
    const newTrack = replaceItemAtIndex(track, index, point);

    setTrack(newTrack);
  };

  const refresh = () => {
    fetchTrackData(eventId, competitorId).then((data) => {
      setTrack(data);
    });

    console.log('Refreshing data');
  }

  useEffect(() => {
    if (!eventId || !competitorId) return;
    fetchTrackData(eventId, competitorId).then((data) => {
      setTrack(data);
    });
  }, [eventId, competitorId]);

  const markerEventHandlers = {
    click: (e) => {
      setSelectedPoint(e.target.options.data.id);
      console.log('click', e.target.options.data.id)
    },
    dragend: (e) => {
      const { lat, lng } = e.target.getLatLng();
      movePoint(e.target.options.data.id, lat, lng);
    },
  };

  const movePoint = (id, lat, lng) => {
    const index = findIndex(track, { id });
    const oldPoint = track[index];
    const newPoint = {
      ...oldPoint,
      latitude: lat,
      longitude: lng,
    };

    updatePointLocation(eventId, id, newPoint)
      .then(() => updatePointState(index, newPoint))
      .catch(() => updatePointState(index, oldPoint)) // Revert

    setSelectedPoint(oldPoint.id);
  }

  return (
    <>
      {partialTrack.map((p, i) => (
        <Marker
          position={[p.latitude, p.longitude]}
          icon={selectedPoint === p.id ? selectedMarkerIcon.current : markerIcon.current}
          data={p}
          key={p.id}
          draggable={true}
          eventHandlers={markerEventHandlers}
        />
      )
      )}
      <Polyline pathOptions={polylineOptions} positions={polyline} />
      <RefreshControl callback={refresh} position="top-right" />
    </>
  )
}

export default RouteEditor;
