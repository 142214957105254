function RefreshControl({ position, callback }) {
  return (
    <div className="fixed top-2 right-2">
      <button
        className="rounded bg-gray-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
        onClick={callback}
      >Refresh</button>
    </div>
  )
}

export default RefreshControl;
