import React from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { eventDataState } from './store/event';

function Header() {
  const { eventId } = useParams();
  const { event } = useRecoilValue(eventDataState(eventId));
  const { name } = event;

  return (
    <header className="flex shadow-lg h-12 p-2 bg-gray-800 text-white border-b items-center border-gray-700">
      <div className="w-16 md:w-64 flex">
        <div className="flex-none w-10 pl-1 pt-2">

        </div>
        <div className="flex-none">
          <b>Route Editor</b>
        </div>
      </div>
      <div className="flex-grow pl-2 w-10 truncate">
        {name}
      </div>
    </header>
  );
}

export default Header;
