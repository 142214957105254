import { createLayerComponent, updateMediaOverlay, } from '@react-leaflet/core';
import { LeafletRotatedImageOverlay as LeafletImageOverlay } from './LeafletRotatedImageOverlay.js';
export const RotatedImageOverlay = createLayerComponent(function createImageOveraly({ topLeft, topRight, bottomLeft, url, ...options }, ctx) {
    const instance = new LeafletImageOverlay(url, topLeft, topRight, bottomLeft, options);
    return { instance, context: { ...ctx, overlayContainer: instance } };
}, function updateImageOverlay(overlay, props, prevProps) {
    updateMediaOverlay(overlay, props, prevProps);
    if (props.url !== prevProps.url) {
        overlay.setUrl(props.url);
    }
});
